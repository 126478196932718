
import BlogTemplate from "../Common/blog-template";
import BlogSection from "../global-brand-solutions/blog_section";
const BlogLayout = ({
  config,
  blogs,
  resources,TemplateName
}) => {
  
  const parse = require("html-react-parser");
  const renderContent = () => {
    switch (TemplateName) {
      case 'Blog_CommonTemplate':
        return <BlogTemplate blogs={blogs} resources={resources} {...config}></BlogTemplate>
      case 'SingleBlog_Template':
        return <BlogSection blogs={blogs}></BlogSection>
      default:
        return <BlogTemplate blogs={blogs} resources={resources} {...config}></BlogTemplate>;
    }
  }

  return (
    <div>
      {renderContent()}
    </div>
  );
};
export default BlogLayout;
