import Nav from '../Common/Nav';
import Footer from '../Common/Footer';
import PreLoad from '../Common/preload';
import MobileContact from '../Common/MobileContact';
import ContactResponse from '../Common/ContactResponse';
import React,{ useState ,useEffect,useContext} from "react";
import { BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import CommonObj from '../CommonObj';
import Home from '../Home/Home';
import AboutUs from '../about-us/AboutUs';
import Resources from '../Resources/Resources';
import People from '../People/People';
import Experience from '../Experience/Experience';
import BrandingCreative from '../global-brand-solutions/branding-creative';
import GlobalOverview from '../global-brand-solutions';
import ContentEditorial from '../global-brand-solutions/content-editorial';
import CreativeProduction from '../global-brand-solutions/creative-production';
import PresentationServices from '../global-brand-solutions/creative-production/presentation-services';
import AccessibilityRemediation from '../global-brand-solutions/creative-production/accessibility-remediation';
import ThreedSolutions from '../global-brand-solutions/creative-production/3d-solutions';
import PremediaPrepress from '../global-brand-solutions/creative-production/premedia-prepress';
import MarketingAutomation from '../global-brand-solutions/marketing-automation-insights';
import SmartWorkSolution from '../Smart-work-solutions';
import ProductivitySolution from '../Smart-work-solutions/productivity-solutions';
import OperationTransformation from '../Smart-work-solutions/operations-transformation';
import RetirementBenefitsAdministration from '../Smart-work-solutions/operations-transformation/retirement-benefits-administration';
import InsuranceServices from '../Smart-work-solutions/operations-transformation/insurance-services';
import ReitAccounting from '../Smart-work-solutions/operations-transformation/reit-accounting-services';
import SmartUtilities from '../Smart-work-solutions/operations-transformation/utilities';
import SharedServices from '../Smart-work-solutions/shared-services-transformation';
import ContactUs from '../contact-us/ContactUs';
import TISOverView from '../technology-innovation-solutions';
import TISInnovationAsAService from '../technology-innovation-solutions/innovation-as-a-service';
import TISIntelligentAutomationSolutions from '../technology-innovation-solutions/intelligent-automation-solutions';
import TISDataServices from '../technology-innovation-solutions/data-services';
import TISITServices from '../technology-innovation-solutions/it-services';
import CaseStudy from '../casestudies/[casestudy]';
import Blog from '../blogs/[blog]';
import { mobileMenu, navigationInit } from '../../assets/js/Components/navigation';
import faq from '../../assets/js/Components/faq';
import Life_Insurance from '../../components/life-insurance';
import General_Insurance from '../../components/general-insurance';
import ImmersiveAndInteractive from '../global-brand-solutions/creative-production/immersive-and-interactive';
import LayoutInt from '../../assets/js/Components/LayoutInt';
import VideoPopUp from '../Common/VideoPopup';
import VideoPopUpTemplate from "../../assets/js/Components/VideoPopUpTemplate";
import retirementVideo from '../../assets/js/Components/retirement_video';
import YoutubePopUp from '../Common/youtubepopup';
import Thankyou from '../Thankyou';
import Bloglist from '../blogs';
import CaseStudiesList from '../casestudies';
import ThoughtPapers from '../ThoughtPapers/[thoughtpapers]';
import ThoughtList from '../thought-papers';
import ThoughtPapersThankyou from '../thoughtpaers-thankyou';
import configJson from '../../Config.json'

export const Context = React.createContext();

const MainSection = () => {

    const [resources, setResource] = useState([]);
    const [videos, setVideos] = useState([]);

    const fetchRecource = async () => 
      {
          console.log(CommonObj.Endpoints.GetResource);
          let res = await fetch(CommonObj.Endpoints.GetResource, {
              method: 'POST',
              body: JSON.stringify({}),
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          const resources = await res.json()
          setResource(resources);
          const timer = setTimeout(() => 
         {
            var domNode= document.getElementById('root');
            domNode.style.display="block";
          }, 300);
      }
      useEffect(() => {
      
            fetchRecource();
            navigationInit();
            mobileMenu();
            retirementVideo();
      },[]);
      useEffect(() => {
        VideoPopUpTemplate();
       
  },[videos]);
      
       return (
       
        <Context.Provider value={[videos, setVideos]}>
        <>
      
         <PreLoad></PreLoad> 
         <div className="PgFixedImg" role="presentation"></div>
         { <div className="PgFixedImg1" role="presentation"></div> }
         <div id="smooth-wrapper">
          <div id="smooth-content">
              <Router>
              <Nav resources={resources} h1Tag="Navigation"></Nav>
                <Switch>
                    <Route path='/Home' exact> <Home resources={resources} /></Route>
                    <Route path='/about-us'> <AboutUs resources={resources} /></Route>
                    <Route path='/Resources' exact> <Resources resources={resources} /></Route>
                    <Route path='/People' exact> <People resources={resources} /></Route>
                    <Route path='/contact-us' exact> <ContactUs resources={resources} /></Route>
                    <Route path='/Experience' exact> <Experience resources={resources} /></Route>
                    <Route path='/global-brand-solutions/branding-creative' exact> <BrandingCreative resources={resources} /></Route>
                    <Route path='/global-brand-solutions' exact> <GlobalOverview resources={resources} /></Route>
                    <Route path='/global-brand-solutions/content-editorial' exact> <ContentEditorial resources={resources} /></Route>
                    <Route path='/global-brand-solutions/creative-production' exact> <CreativeProduction resources={resources} /></Route>
                    <Route path='/global-brand-solutions/creative-production/presentation-services' exact> <PresentationServices resources={resources}  /></Route>
                    <Route path='/global-brand-solutions/creative-production/accessibility-remediation' exact> <AccessibilityRemediation resources={resources} /></Route>
                    <Route path='/global-brand-solutions/creative-production/3d-solutions' exact> <ThreedSolutions resources={resources} /></Route>
                    <Route path='/global-brand-solutions/creative-production/premedia-prepress' exact> <PremediaPrepress resources={resources} /></Route>
                    <Route path='/global-brand-solutions/marketing-automation-insights' exact> <MarketingAutomation resources={resources} /></Route>
                    <Route path='/Smart-work-solutions' exact> <SmartWorkSolution resources={resources} /></Route>
                    <Route path='/Smart-work-solutions/productivity-solutions' exact> <ProductivitySolution resources={resources} /></Route>
                    <Route path='/Smart-work-solutions/operations-transformation' exact> <OperationTransformation resources={resources} /></Route>
                    <Route path='/smart-work-solutions/operations-transformation/retirement-benefits-administration' exact> <RetirementBenefitsAdministration resources={resources} /></Route>
                    <Route path='/smart-work-solutions/operations-transformation/insurance-services' exact> <InsuranceServices resources={resources} /></Route>
                    <Route path='/smart-work-solutions/operations-transformation/reit-accounting-services' exact> <ReitAccounting resources={resources} /></Route>
                    <Route path='/smart-work-solutions/operations-transformation/utilities' exact> <SmartUtilities resources={resources} /></Route>
                    <Route path='/smart-work-solutions/shared-services-transformation' exact> <SharedServices resources={resources} /></Route>
                    <Route path='/technology-innovation-solutions' exact> <TISOverView resources={resources} /></Route>
                    <Route path='/technology-innovation-solutions/innovation-as-a-service' exact> <TISInnovationAsAService resources={resources} /></Route>
                    <Route path='/technology-innovation-solutions/intelligent-automation-solutions' exact> <TISIntelligentAutomationSolutions resources={resources} /></Route>
                    <Route path='/technology-innovation-solutions/data-services' exact> <TISDataServices resources={resources} /></Route>
                    <Route path='/technology-innovation-solutions/it-services' exact> <TISITServices resources={resources} /></Route>
                    <Route path='/global-brand-solutions/creative-production/immersive-and-interactive' exact> <ImmersiveAndInteractive resources={resources} /></Route>
                    <Route path="/casestudies/:PageName"exact ><CaseStudy resources={resources} /></Route>
                    <Route path="/blogs/:PageName"exact ><Blog resources={resources} /></Route>
                    <Route path='/life-insurance' exact> <Life_Insurance resources={resources} /></Route>
                    <Route path='/general-insurance' exact> <General_Insurance resources={resources} /></Route>
                    <Route path='/Thankyou' exact> <Thankyou resources={resources} /></Route>
                    <Route path='/ThoughtPapers-Thankyou' exact> <ThoughtPapersThankyou resources={resources} /></Route>
                    <Route path='/blogs' exact> <Bloglist resources={resources} /></Route>
                    <Route path='/casestudies' exact> <CaseStudiesList resources={resources} /></Route>
                    <Route path="/thoughtPapers/:PageName"exact ><ThoughtPapers resources={resources} /></Route>
                    <Route path='/thoughtpapers' exact> <ThoughtList resources={resources} /></Route>
                    <Route Path='/'><Redirect to="/Home" /></Route>
                 </Switch>
                 <Footer resources={resources}></Footer>
                 </Router>
                
             </div>
          </div>
          { !!videos.length && <VideoPopUp videos={videos} resources={resources} ></VideoPopUp>}
          <YoutubePopUp></YoutubePopUp>
            <div id="loadDiv">
            <img id="loading-image" src={configJson.S3ImageURL+"/static/Images/loder.gif"} alt="Loading" />
            </div>
        </>
        </Context.Provider>
       )
    }
export default MainSection;