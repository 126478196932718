
import Modal from "./modal";
export default function modalInit(isModalVisible,setIsModalVisible) {
  const DownloadPaperWrapper = document.querySelectorAll(".download-thoughtPaperBtn");
 
  
  function BindDonwloadPaperClickfn()
  {
    DownloadPaperWrapper.forEach((downloadBtn) => {
      downloadBtn.addEventListener("click", (e) => {
      e.preventDefault();
      setIsModalVisible(true);
    })
  });
  }
  BindDonwloadPaperClickfn();

}
