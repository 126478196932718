import React, { useState, useEffect } from "react";
import Anchor from "../Icons/Anchor";
import Date from "../Format/Date";
import CommonObj from "../CommonObj";
import configJson from '../../Config.json';

const BlogListTemplate = ({ heading, type, bottomspace, searchText, sort, filter, currentPage, setCurrentPage }) => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const pageSize = parseInt(configJson.PageSize) + 1;

  const fetchData = async () => {
    setLoading(true);
    setBlogs([]);
    const data = {
      'contentType': CommonObj.ContentType.Blogs,
      'search': searchText,
      'sort': sort,
      'pageName': CommonObj.PageNameList.Blogs,
      'pageSize':pageSize,
      'PageNo': currentPage,
      'Filter': filter,
    };

    const res = await fetch(CommonObj.Endpoints.ContentListEndpoint, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const Data = await res.json();
    setBlogs(Data.contents);
    setTotalRow(Data?.contents[0]?.totalRow || 0);
    setLoading(false);
  };

  useEffect(() => {
    const debounceFetch = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(debounceFetch);
  }, [searchText, sort, filter, currentPage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const totalPages = Math.ceil(totalRow / pageSize);

  const handlePageChange = (pageNumber) => {
    window.scrollTo(400, 400);
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    window.scrollTo(400, 400);
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    window.scrollTo(400, 400);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPagination = () => {
    const maxVisiblePages = isMobile ? 5 : totalPages; 
    const halfVisible = Math.floor(maxVisiblePages / 2);
    let startPage = Math.max(1, currentPage - halfVisible);
    let endPage = Math.min(totalPages, currentPage + halfVisible);

    if (endPage - startPage < maxVisiblePages - 1) {
      if (startPage === 1) {
        endPage = Math.min(maxVisiblePages, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - maxVisiblePages + 1);
      }
    }

    return (
      <ul>
        {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
          <li
            className={(startPage + index === currentPage) ? "active" : ""}
            key={startPage + index}
            onClick={() => handlePageChange(startPage + index)}
          >
            {startPage + index}
          </li>
        ))}
      </ul>
    );
  };

  if (loading) {
    return <div className="loader"><img src="/assets/img/loading.gif" alt="Loading..." /></div>;
  }

  const parse = require("html-react-parser");
  /* NOSONAR */

  if (blogs != null && blogs.length > 0) {
    return (
      <div className={(bottomspace ? "container-mb" : "") + " bg-gray mat-10"}>
        <section className="container blogs-grid">
          <div className="holder-thumbnails_blog">
            {blogs.map((blog) => (
              <a key={blog.id} className={`carousel_blog_holder ${blog.anchorlink == null ? "noClick" : ""}`} href={`/blogs${blog.anchorlink || ""}`}>
                <div className="carousel_blog">
                  <div className="carousel__img_blog">
                    <img src={blog.authorImage || configJson.S3ImageURL+"/static/Images/Blog_Author_Image.jpg"} alt="No Image" />
                  </div>
                  <div className="carousel__name_blog">
                    <h3 title={blog.authorName}>{blog.authorName}</h3>
                    <p className="carousel__title--3">
                      {blog.date && <Date dateString={blog.date} />}
                    </p>
                  </div>
                  <div className="carousel__content_blog flow">
                    <p className="color-black carousel__title--2 blog_title">
                      {blog.title && parse(blog.title)}
                    </p>
                  </div>
                </div>
              </a>
            ))}
          </div>
          <div className="pagination_outer">
            <div className="pagination">
              <div className={`pagi_left_icon ${currentPage === 1 ? "hide" : ""}`} onClick={handlePreviousPage}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 6L9 12L15 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              {renderPagination()}
              <div className={`pagi_right_icon ${currentPage === totalPages ? "hide" : ""}`} onClick={handleNextPage}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 18L15 12L9 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <span className="not-found">No Records Found</span>;
  }
};

export default BlogListTemplate;
