import React, { useEffect, useState } from "react";
import Parallax from "../Common/Parallax";
import CommonObj from "../CommonObj";
import hidePreLoad from "../../assets/js/Components/hidePreLoad";
import parallax, { scrollRefresh } from "../../assets/js/Components/parallax";
import Section from "../Section/Section";
import { Helmet } from "react-helmet";
import PreLoad from "../Common/preload";
import Hero from '../Common/Hero';
import BlogTemplates from "../Layouts/BlogLayout";
import IconTemplates from "../Layouts/IconLayout";
import FAQTemplates from "../Layouts/FAQLayout";
import BlogLayout from "../Layouts/BlogLayout";
import FAQLayout from "../Layouts/FAQLayout";
import IconLayout from "../Layouts/IconLayout";
import CaseStudyLayout from "../Layouts/CaseStudyLayout";
import ThumbnailLayout from "../Layouts/ThumbnailLayout";
import { useParams } from "react-router-dom";
import StaticResourceText from "../Format/static-resource-text";
import CommonFunc from "../../assets/js/Components/CommonFunc";
import PeoplePgFunc from "../../assets/js/Components/PeoplePgFunc";
import Modal from "../../assets/js/Components/modal";
import CarouselLayout from "../Layouts/CarouselLayout";
import BreadCrumb from "../Common/breadcrumb";
import modalInit from "../../assets/js/Components/modalInit";
import Modernizr from "../../assets/js/vendor/modernizr-custom"

export default function ThoughtPapers(props) {
  const parse = require("html-react-parser");
  const [page, setPage] = useState();
  const { PageName } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);

useEffect(() => {
  if (!(Modernizr.touchevents || Modernizr.forcetouch) && document.documentElement.clientWidth <= 1023) { /* NOSONAR */
  const handleScroll = () => {
    const smoothContentDiv = document.getElementById("smooth-content");
    const scrollTop = window.scrollX;
    smoothContentDiv.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -${scrollTop / 2}, 0, 1)`;
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}
}, []);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const fetchData = async () => {
    let pageName = "/" + PageName;
    let res = await fetch(CommonObj.Endpoints.GetPage, {
      method: 'POST',
      body: JSON.stringify(pageName),
      headers: {
        'Content-Type': 'application/json',
      }
    })

    let page = await res.json();
    setPage(page);
    CommonFunc(page);
    PeoplePgFunc(page);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (page != null) {
      parallax();
      modalInit(isModalVisible,setIsModalVisible);
      scrollRefresh();
    }
    window.scrollTo(0, 0);
    hidePreLoad();
  }, [page]);

  const templateHandlers = {
    Blogs: (Templates) => {
      const blogs = page.contents.filter(x => x.type === CommonObj.ContentType.Blogs && (x.tags === null || x.tags === Templates.tagName));
      const config = JSON.parse(Templates.templateOptions);
      return <BlogLayout config={config} blogs={blogs} resources={props.resources} TemplateName={Templates.templateName} />;
    },
    Carousels: (Templates) => {
      const blogs = page.contents.filter(x => x.type === CommonObj.ContentType.Carousels && (x.tags === null || x.tags === Templates.tagName));
      const config = JSON.parse(Templates.templateOptions);
      return <CarouselLayout config={config} blogs={blogs} resources={props.resources} TemplateName={Templates.templateName} />;
    },
    FAQS: (Templates) => {
      const faqs = page.contents.filter(x => x.type === CommonObj.ContentType.FAQS && (x.tags === "" || x.tags === Templates.tagName));
      return <FAQLayout faqs={faqs} resources={props.resources} TemplateName={Templates.templateName} />;
    },
    Icons: (Templates) => {
      const config = JSON.parse(Templates.templateOptions);
      const icons = page.contents.filter(x => x.type === CommonObj.ContentType.Icons && (x.tags === "" || x.tags === Templates.tagName));
      return <IconLayout config={config} Icons={icons} resources={props.resources} TemplateName={Templates.templateName} />;
    },
    CaseStudies: (Templates) => {
      const caseStudies = page.contents.filter(x => x.type === CommonObj.ContentType.CaseStudies && (x.tags === "" || x.tags === Templates.tagName));
      const config = JSON.parse(Templates.templateOptions);
      return <CaseStudyLayout config={config} CaseStudies={caseStudies} resources={props.resources} TemplateName={Templates.templateName} />;
    },
    Thumbnails: (Templates) => {
      const thumbnails = page.contents.filter(x => x.type === CommonObj.ContentType.Thumbnails && (x.tags === "" || x.tags === Templates.tagName));
      const config = JSON.stringify(Templates.templateOptions);
      return <ThumbnailLayout config={config} Thumbnails={thumbnails} resources={props.resources} TemplateName={Templates.templateName} />;
    }
  };

  if (page != null) {
    return (
      <>
        <div className="thought__papers">
          <Section PageName={page.name} async>
            <Helmet>
              <title>{page.title}</title>
              <meta name="description" content={page.metaDescription} />
              <meta name="keywords" content={page.metaKeywords} />
            </Helmet>
            <Parallax pageheroimage={page.image ? page.image : ''}></Parallax>
            <Parallax pageFixedImage1={page.backgroundImage ? page.backgroundImage : ""}></Parallax>
            <Hero landingpage={false} heading1={page.title && parse(page.title)} resources={props.resources} desc={page.shortDesc} contactUs={false}></Hero>
            <BreadCrumb page={page?.name} />
            <Modal isOpen={isModalVisible} onClose={closeModal} title="Start playing a whole new field!" ThoughtPaperName={page.name} >
                  <p>This is a simple modal. You can add more content here!</p>
             </Modal>
            <div className="bg-white mt-1">
              <section className="container">
                <div className="inner">
                  <div className="center-content para90">
                    {page.longDesc && parse(page.longDesc)}
                  </div>

                </div>
              </section>
            </div>
            {page.conetentTemplates && page.conetentTemplates.map((Templates, i) => {
              if (Templates.contentType != '') {
                const Handler = templateHandlers[Templates.contentType];
                return Handler ? Handler(Templates) : null;
              }
              else {
                return <StaticResourceText resources={props.resources} reskey={Templates.tagName}></StaticResourceText>
              }
            })}
          </Section>
        </div>
      </>
    );
  } else {
    return <PreLoad></PreLoad>;
  }
}
