import React from "react";
import AbTriangle from "./AbTriangle";
import Date from "../Format/Date";
import StaticResourceText from "../Format/static-resource-text";
import configJson from '../../Config.json';

const BlogHero = ({
  landingpage,
  title,
  authorimg,
  authorname,
  blogDate,
  resources,
}) => {
  const parse = require("html-react-parser");

  return (
    <div className={"hero__wrapper hero__parallax " + landingpage} async>
      <div className="pin0"></div>
      <AbTriangle></AbTriangle>

      <section className="container">
        <div id="hero" className="hero hero__blog mt-50">
          <h2 className="color-white blog__category">
            <StaticResourceText
              resources={resources}
              reskey={"BLOGS-PAGE-HEADING"}
            ></StaticResourceText>
          </h2>
          {title && <h2 className="color-white bold">{parse(title)}</h2>}
          <div className="blog__author">
            {authorimg && (
              <div className="blog__author__img">
                <img src={authorimg} alt={authorname} />
              </div>
            )}

            {!authorimg && (
              <div className="blog__author__img">
                <img src={configJson.S3ImageURL+"/static/Images/Blog_Author_Image.jpg"} alt={authorname} />
              </div>
            )}

            <div className="blog__author__details">
              {authorname && <h3 className="color-white mt0">{authorname}</h3>}

              {blogDate && (
                <h4 className="color-white">
                  {blogDate && <Date dateString={blogDate}></Date>}
                </h4>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default BlogHero;
