import React from "react";
import configJson from '../../Config.json'

const PreLoad = () => {
  return (
    <>
      <div async>
        <div className="preload" role="complementary">
          <div className="preload__icon">
            <img src={configJson.S3ImageURL+"/static/SVG/Triangles.svg"} alt="triangles image" />
            <div className="preload__text">Loading...</div>
         
          </div>
        </div>
      </div>
     
    </>
  );
};
export default PreLoad;