import React, { useEffect, useState } from 'react';
import Hero from '../Common/Hero';
import formInit from '../../assets/js/Components/formInit';
import parallax, { scrollRefresh } from '../../assets/js/Components/parallax';
import { mobileMenu, navigationInit } from '../../assets/js/Components/navigation';
import hidePreLoad from '../../assets/js/Components/hidePreLoad';
import resizeWindow from '../../assets/js/Components/resizeWindow';
import CommonObj from '../CommonObj';
import Parallax from '../Common/Parallax';
import BlogTemplate from '../Common/blog-template';
import FAQTemplate from '../Common/faq-template';
import faq from '../../assets/js/Components/faq';
import PageNotFound from '../Common/PageNotFound';
import InfographicsTemplate from '../Common/InfographicsTemplate';
import TestimonialExcerptSection from '../Common/TestimonialExcerptSection';
import heroCarousel from '../../assets/js/Components/heroCarousel';
import { Helmet } from 'react-helmet';
import Section from '../Section/Section';
import carousel from '../../assets/js/Components/carousel';
import ResourceTemplate from '../Common/ResourceTemplate';
import '../../components/loader.css';
import PreLoad from '../Common/preload';
import renderPage from '../../assets/js/Components/CommonFunc';
import PeoplePgFunc from '../../assets/js/Components/PeoplePgFunc';
import StaticResourceText from '../Format/static-resource-text';
import BreadCrumb from '../Common/breadcrumb';
import ThoughtPapersTemplate from '../Common/thought-papers-template';

function Resources(props) {

    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        let pageName = CommonObj.PageNameList.Resources;
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify(pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        PeoplePgFunc(page);
    }
   function PageScroller()
  {
    const timer = setTimeout(() => 
    {
      setLoading(false);
    }, 3000);
  }
    useEffect(() => {
        fetchData();
        PageScroller();
        renderPage();
    }, []);
    useEffect(() => {
        if (page != null) {
            heroCarousel();
            carousel();
            formInit();
            parallax();
            faq();
           scrollRefresh();
        }
        window.scrollTo(0, 0);
        hidePreLoad();
        resizeWindow();
    }, [page,loading]);

    let faqSchemaJson = {};
    let faqSchemaMainEntity = [];
    if (page != null) {
        const parse = require('html-react-parser');
        let smallthumbnails = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);
        let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
        let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
        let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
        const resource_cs_array = props.resources != null ? props.resources.filter(x => x.name === 'RESOURCES-CS-TITLE') : [];
        let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
        let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials);
        let thoughtpapers = page.contents.filter(x => x.type == CommonObj.ContentType.ThoughtPapers);
        {
            !!faqs.length &&
              faqs.map((faq) => {
                  if (faq.shortDesc != null) {
                    /* NOSONAR */
                    faqSchemaMainEntity.push(
                      `{'@type':'Question',
                         'name':'${faq.title.replace(/<([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/g,"")}',
                         'acceptedAnswer':{'@type':'Answer',
                                           'text':'${faq.shortDesc.replace(/<([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/g,"")}'} }`
                    );
                  } else {
                    /* NOSONAR */
                    faqSchemaMainEntity.push(
                      `{'@type':'Question',
                        'name':'${faq.title.replace(/<([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/g,"")}',
                        'acceptedAnswer':{'@type':'Answer','text':''} }`
                    );
                  }
                });
            }
            faqSchemaJson = {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: [],
            };
            faqSchemaJson.mainEntity.push(faqSchemaMainEntity);
            function addFAQSchemaMarkup() {
              return {
                __html: JSON.stringify(faqSchemaJson),
              };
            }
       
        return (
            <>
                {loading ? (<PreLoad></PreLoad>) : (
                    <Section PageName={page.name} async>
                      {console.log(thoughtpapers,"WEB")
                      }
                        <Helmet>
                            <title>{page.title}</title>
                            <meta name="description" content={page.metaDescription} />
                            <meta name="keywords" content={page.metaKeywords} />
                            {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
                            {!!faqs.length && (
                             <script nonce="GoCMSWebNAUSGHDV" type="application/ld+json" dangerouslySetInnerHTML={addFAQSchemaMarkup()} key="faqschema-jsonld"/>
                            )}
                        </Helmet>
                        {page.pagefixedimage && <div className="pin2" role="presentation"></div>}
                        {page.pageFixedImage1 && <div className="pin3" role="presentation"></div>}
                        <Parallax pageheroimage={page.image ? page.image : ''}></Parallax>
                        <Hero landingpage={false} heading1={page.heading} desc={page.shortDesc} carouselList={heroCarouselList} resources={props.resources} ></Hero>
                        <BreadCrumb page={page?.name}/>
                        {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
                        {!!thoughtpapers.length && <ThoughtPapersTemplate thoughtpapers={thoughtpapers} resources={props.resources} heading={resource_cs_array.length > 0 ? parse(resource_cs_array[0].value) : CommonObj.ResourceKeyDefaults['RESOURCES-CS-TITLE']}
                            smallthumbnails={smallthumbnails} type={'resource'} bottomspace={false}></ThoughtPapersTemplate>}
                        {!!smallthumbnails.length && <ResourceTemplate heading={resource_cs_array.length > 0 ? parse(resource_cs_array[0].value) : CommonObj.ResourceKeyDefaults['RESOURCES-CS-TITLE']}
                            smallthumbnails={smallthumbnails} type={'resource'} bottomspace={false}></ResourceTemplate>}
                        {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={props.resources}></TestimonialExcerptSection>}
                        {!!blogs.length && <BlogTemplate bgblue={true} alignleft={true} blogs={blogs} notopspace={false} resources={props.resources}></BlogTemplate>}
                        <StaticResourceText resources={props.resources} reskey={"RESOURCE_POLICY_UPLOAD"}></StaticResourceText>
                        {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={false} resources={props.resources}></FAQTemplate>}
                    </Section>
                )}
            </>
        )
    }
    else {
        return (
            <>
            <PreLoad></PreLoad>
                {/* {loading ? (<PreLoad></PreLoad>) : (
                    <PageNotFound></PageNotFound>
                )} */}
            </>
        )
    }
}



export default Resources;