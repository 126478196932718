import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollSmoother } from "gsap/dist/ScrollSmoother";
import Modernizr from "../vendor/modernizr-custom.js"

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

let smoother = {};

export function scrollRefresh() {
  ScrollTrigger.refresh();
}
export function scrollMemory() {
  ScrollTrigger.clearScrollMemory();
}

function parallax() { /* NOSONAR */
  const heroPin = document.querySelector(".pin");

  if (!heroPin) {  /* NOSONAR */
    return false;
  }

  const tl = gsap.timeline();

  const top =
    document.querySelector(".hero__wrapper").clientHeight <
    window.innerHeight * 0.85
      ? -30
      : -10; /* NOSONAR */

  tl.to(heroPin, { yPercent: top }, 0);
  tl.fromTo(
    heroPin,
    {
      clipPath: "polygon(0 0%, 100% 0%, 100% 100%, 0 100%)",
      webkitClipPath: "polygon(0 0%, 100% 0%, 100% 100%, 0 100%)",
    },
    {
      clipPath: "polygon(0 0%, 100% 0%, 100% 60%, 0 60%)",
      webkitClipPath: "polygon(0 0%, 100% 0%, 100% 60%, 0 60%)",
    },
    0
  );

  ScrollTrigger.refresh();

  ScrollTrigger.create({
    trigger: heroPin,
    start: "top top",
    end: "+=100%",
    scrub: true,
    pin: true,
    pinSpacing: false,
    pinnedContainer: true,
    animation: tl,
  });

  const iconsSection = document.querySelector(".icons-section");
  const iconsSection1 = document.querySelector(".icons-section1");

  const image2 = document.querySelector(".pin2");
  const triggerElement = iconsSection || iconsSection1; /* NOSONAR */

  if (triggerElement) {
    const tl2 = gsap.timeline();
    tl2.from(".list-container", { yPercent: 100, ease: "none" });

    const img2Tl = gsap.timeline().from(image2, {
      clipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)",
      webkitClipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)",
    });
    ScrollTrigger.create({
      trigger: triggerElement,
      start: "top bottom",
      scrub: true,
      end: "+=200px",
      pinSpacing: false,
      animation: img2Tl,
    });
  }
  const image3 = document.querySelector(".pin3");
  const iconsSection2 = document.querySelector(".icons-section2");
  const triggerElement1 = iconsSection2;

  if (triggerElement1) { /* NOSONAR */
    const tl2 = gsap.timeline();
    tl2.from(".list-container", { yPercent: 100, ease: "none" });

    const img3Tl = gsap
      .timeline()
      .from(image3, {
        clipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)",
        webkitClipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)",
      })
      .to(image2, { autoAlpha: 0 }, 0);
    ScrollTrigger.create({
      trigger: triggerElement1,
      start: "top bottom",
      scrub: true,
      end: "+=200px",
      pinSpacing: false,
      animation: img3Tl,
    });
  }

  (function () {
    const videoEl = document.getElementById("video_home");
    if (videoEl) { /* NOSONAR */
      ScrollTrigger.create({
        trigger: videoEl,
        start: "top 50%",
        end: "bottom 50%",
        onLeave: () => {
          videoEl.pause();
        },
        onLeaveBack: () => {
          videoEl.pause();
        },
      });
    }
  })();

  // Panels Animation
  function panelsAnimation() {
    const clipAnimate = Array.from(document.querySelectorAll(".clip-animate"));
    if (!clipAnimate.length) { /* NOSONAR */
      return false;
    }
    const panels1Contents = Array.from(
      clipAnimate[0].querySelectorAll(".design__element path, .inner")
    );
    const panels2Contents = Array.from(
      clipAnimate[1].querySelectorAll(".design__element path, .inner")
    );
    const panels3Contents = Array.from(
      clipAnimate[2].querySelectorAll(".design__element path, .inner")
    );

    const panel1Tl = gsap.timeline({
      defaults: {
        ease: "sine.inOut",
      },
    });
    panel1Tl
      .from(panels1Contents, {
        xPercent: gsap.utils.wrap([10, 20, 30, 10, -10]),
        autoAlpha: 0,
        stagger: 0.05,
      })
      .addLabel("firstout")
      .to(
        clipAnimate[0],
        {
          yPercent: -20,
          clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
          webkitClipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
          ease: "sine.inOut",
        },
        "firstout"
      );

    ScrollTrigger.create({
      trigger: ".panels__wrapper",
      start: "top bottom",
      //           //   end: '50%',
      scrub: true,
      animation: panel1Tl,
    });
    const panelTl = gsap.timeline({
      defaults: {
        ease: "sine.inOut",
      },
    });

    panelTl

      .from(clipAnimate[1], { scale: 1.2 }, "firstout")
      .from(
        panels2Contents,
        {
          xPercent: gsap.utils.wrap([10, 20, 30, 10, -10]),
          autoAlpha: 0,
          stagger: 0.05,
        },
        "firstout"
      )
      .addLabel("secondout")
      .to(
        clipAnimate[1],
        {
          yPercent: -20,
          clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
          webkitClipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
          ease: "sine.inOut",
        },
        "secondout"
      )
      .to(
        panels2Contents,
        { yPercent: -20, autoAlpha: 0, stagger: 0.05 },
        "secondout"
      )
      .from(clipAnimate[2], { scale: 1.2 }, "secondout")
      .from(
        panels3Contents,
        {
          xPercent: gsap.utils.wrap([10, 20, 30, 10, -10]),
          autoAlpha: 0,
          stagger: 0.05,
        },
        "secondout"
      );

    ScrollTrigger.create({
      trigger: ".panels__wrapper",
      start: "top top",
      end: "+=200%",
      scrub: true,
      pin: true,
      pinSpacing: true,

      animation: panelTl,
    });

    gsap.set(".clip-animate", {
      zIndex: (i, target, targets) => targets.length - i,
    });
  }

  function scrollIcon() {
    const sIcon = document.querySelector(".field");
    if (sIcon) { /* NOSONAR */
      gsap.to(sIcon, {
        yPercent: 10,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: ".hero__wrapper",
          start: "top top",
          end: "+=40%",
          scrub: true,
        },
      });
    }
  }

  scrollIcon();

  ScrollTrigger.matchMedia({
    "(min-width: 760px)": function () {
      panelsAnimation();
    },
  });


  function boxAnimation() {
    const col = document.querySelectorAll(".col2");
    if (!col) { /* NOSONAR */
      return false;
    }
    col.forEach((element) => {
      const colingContainer = element.querySelector(".colimg_container");

      const timeLine = gsap.timeline({
        defaults: {
          ease: "Power1.easeInOut",
        },
      });
      if (colingContainer) { /* NOSONAR */
        const bg = element.querySelector(".bg");
        let _xPercent = -100;
        if (bg.classList.contains("bg4") || bg.classList.contains("bg2")) { /* NOSONAR */
          _xPercent = 100;
        }
        timeLine.set(element, { rotation: 0.01 })
          .from(bg, { scale: 1.5 }, 0)
          .from(colingContainer, { xPercent: _xPercent }, 0);
      }

      ScrollTrigger.create({
        trigger: element,
        start: "top 80%",
        end: "+=50%",
        scrub: true,
        animation: timeLine,
      });
    });
    let open = document.querySelector('#onetrust-pc-btn-handler');
    let modal = document.querySelector('#onetrust-pc-sdk'); 
    let close = document.querySelector('#close-pc-btn-handler');
    let rejectAll = document.querySelector('.ot-pc-refuse-all-handler');
    let onConfirm = document.querySelector('.onetrust-close-btn-handler');
    let footeropen = document.querySelector('#ot-sdk-btn'); 
    let cookieBackdrop = document.querySelector('.onetrust-pc-dark-filter');
    let allowAll = document.querySelector('#accept-recommended-btn-handler');
   

    open?.addEventListener('click', e => {
      close.style.display = "block";
      smoother.paused(true);
      gsap.set(modal, {
        display: 'block',
        position: 'fixed',
        top: '5%',
        left: 0,
        opacity:1,
        visibility: 'visible',
        width: '100%',
        height: '485.6px',
        overflow: 'auto',
      });
       gsap.set(cookieBackdrop, {
      zIndex:'2147483646'
       });
    });

    footeropen?.addEventListener('click', e => {
      smoother.paused(true);
      close.style.display = "none";
      gsap.set(modal, {
        display: 'block',
        position: 'fixed',
        top: 10,
        left: 0,
        opacity:1,
        visibility: 'visible',
        width: '100%',
        height: '485.6px',
        overflow: 'auto',
      })
      gsap.set(cookieBackdrop, {
        zIndex:'2147483646',
        display: 'block'
         });
    });



    
    close?.addEventListener('click', e => {
      smoother.paused(false);
    });

    rejectAll?.addEventListener('click', e => {
      smoother.paused(false);
    });

    onConfirm?.addEventListener('click', e => {
      smoother.paused(false);
    });
    allowAll?.addEventListener('click', e => {
      smoother.paused(false);
    });
  }

  boxAnimation();
}

export function smootherStop() {
  if (!Modernizr.touchevents) {
    smoother.paused(!smoother.paused());
  }
}
export default parallax;
