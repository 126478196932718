import React, { useState } from "react";
import LearnMore from "../Icons/learnMore";

const PillarThumbnailTemplate = ({
  thumbnails,
  bottomspace,
  isPeoplePage,
  isIconTemplate,
  icons,
  keyword,
  isInnovationSolution,
  isGap0,
  showLearnMore
}) => {
  const parse = require("html-react-parser");
  let content;

  content = (
    <div
      className={
        (bottomspace ? "container-mb" : "") +
        " parallax-container icons-section " +
        (isPeoplePage ?? isIconTemplate ? "thumbnail-section" : "")
      }
    >
      <section className="container">
        <div
          className={
            "holder-thumbnail " +
            (isPeoplePage || isIconTemplate ? "thumbnail-section" : "") +
            (isGap0 ? " gap0" : "")
          }
        >
          {thumbnails &&
            thumbnails.map((thumbnail) => {
              if (!isPeoplePage ?? true) {
                return (
                  <div
                    className={
                      "thumbnail " + (isInnovationSolution ? "innov" : "")
                    }
                    key={thumbnail.title}
                  >
                    <div>
                      <div className="thumbnail__icon">
                        <img
                          src={thumbnail.image}
                          alt={thumbnail.title + " icon"}
                        />
                      </div>
                      <div className="thumbnail__title flow">
                        <h2>{parse(thumbnail.title)}</h2>
                        <div
                          className={
                            isInnovationSolution ? "tech-innov-sol" : ""
                          }
                        >
                          {thumbnail.shortDesc && parse(thumbnail.shortDesc)}
                        </div>
                      </div>
                    </div>
                    {showLearnMore &&
                    <LearnMore
                      className="btn__primary"
                      buttonText="Learn More"
                      link={thumbnail.link}
                    ></LearnMore>
              }
                  </div>
                );
              } else {
                return (
                  <div
                    className="thumbnail mar-rem"
                    key={thumbnail.title}
                    async
                  >
                    <div>
                      <div className="thumbnail__icon">
                        <img
                          src={thumbnail.image}
                          alt={thumbnail.title + " icon"}
                        />
                      </div>
                      <div className="thumbnail__title flow">
                        <h2>{thumbnail.title && parse(thumbnail.title)}</h2>
                        <div className="people-pillar">
                          {thumbnail.shortDesc && parse(thumbnail.shortDesc)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          {icons &&
            icons.map((icon) => {
              return (
                <div className="thumbnail mar-rem2" key={icon.title} async>
                  <div className="thumbnail__icon">
                    <img src={icon.image} alt={icon.title + " icon"} />
                  </div>
                  <div className="thumbnail__title flow">
                    {icon.shortDesc && parse(icon.shortDesc)}
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </div>
  );

  return <>{content}</>;
};
export default PillarThumbnailTemplate;
